import React, { useCallback, useMemo } from "react";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useToggle from "react-use/lib/useToggle";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import clsx from "clsx";

import map from "lodash/map";

import { useMutation, useQuery } from "@apollo/client";

import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { Link, useHistory, useParams } from "react-router-dom";
import { Controller, FormProvider, useForm } from "react-hook-form";

import Input from "../../../components/Input";
import RoleBlock from "../../../components/RoleBlock";

import {
  QUERY_USER,
  QUERY_USERS,
  QUERY_CURRENT_USER,
} from "../../../config/graphql/query";
import {
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  MUTATION_RESEND_VERIFICATION_EMAIL,
} from "../../../config/graphql/mutation";

import { UserRolesPriority } from "../../../config/const/common";
import LoadingSpinner from "../../../components/Spinner";
import { useCurrentClient } from "../../../context/Client";

const ADMIN_ROLE_OPTIONS: UserRole[] = [
  "TORO1",
  "ADMIN",
  "CLIENT_ADMIN",
  "CLIENT",
  "USER",
  "ACTIVITY",
];

const CLIENT_ADMIN_ROLE_OPTIONS: UserRole[] = [
  "CLIENT_ADMIN",
  "CLIENT",
  "USER",
  "ACTIVITY",
];

const CLIENT_ROLE_OPTIONS: UserRole[] = ["CLIENT", "USER", "ACTIVITY"];

export type UserFieldValues = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  language: string;
  roles: UserRole[];
  passwordConfirm?: string;
  emailVerified?: boolean;
};

const UserCreate = React.memo(() => {
  const [show, setShow] = useToggle(false);

  const history = useHistory();

  const { t } = useTranslation(["user", "common"]);

  const { id } = useParams<{ id: string }>();

  const { data: currentUserData } = useQuery<{ me: IUser }>(QUERY_CURRENT_USER);

  const client = useCurrentClient();
  const clientCode = client?.code;
  const clientType = client?.type;

  const roleOptions = useMemo(() => {
    const roles = currentUserData?.me?.roles;

    if (!(Array.isArray(roles) && roles.length > 0)) {
      return [];
    }

    if (roles.includes("ADMIN")) {
      return clientCode === "hapag"
        ? [...ADMIN_ROLE_OPTIONS, "PICK_UP"]
        : ADMIN_ROLE_OPTIONS;
    }

    if (roles.includes("CLIENT_ADMIN")) {
      return clientCode === "hapag"
        ? [...CLIENT_ADMIN_ROLE_OPTIONS, "PICK_UP"]
        : CLIENT_ADMIN_ROLE_OPTIONS;
    }

    return clientCode === "hapag"
      ? [...CLIENT_ROLE_OPTIONS, "PICK_UP"]
      : CLIENT_ROLE_OPTIONS;
  }, [currentUserData, clientCode]);

  const schema = useMemo(() => {
    return yup.object().shape({
      ...(id
        ? {
            email: yup.string(),
            firstName: yup.string(),
            lastName: yup.string(),
            password: yup
              .string()
              .nullable()
              .transform((v, o) => (o === "" ? null : v))
              .min(8, t("user:user.yup.password.min", { count: 8 }))
              .matches(/[a-z]+/, t("user:user.yup.password.lowercase"))
              .matches(/[A-Z]+/, t("user:user.yup.password.uppercase"))
              .matches(/\d+/, t("user:user.yup.password.number"))
              .matches(/[@$!%*#?&]+/, t("user:user.yup.password.special")),
            passwordConfirm: yup
              .string()
              .test(
                "passwords-match",
                t("resetPassword:yup.confirmPassword.match"),
                function validate(value) {
                  if (this.parent.password) {
                    return this.parent.password === value;
                  }
                  if (value) {
                    return false;
                  }
                  return true;
                },
              ),
          }
        : {
            email: yup.string().required(),
            firstName: yup.string().required(),
            lastName: yup.string().required(),
            password: yup
              .string()
              .required(t("user:user.yup.password.required"))
              .min(8, t("user:user.yup.password.min", { count: 8 }))
              .matches(/[a-z]+/, t("user:user.yup.password.lowercase"))
              .matches(/[A-Z]+/, t("user:user.yup.password.uppercase"))
              .matches(/\d+/, t("user:user.yup.password.number"))
              .matches(/[@$!%*#?&]+/, t("user:user.yup.password.special")),
            passwordConfirm: yup
              .string()
              .required(t("user:user.yup.confirmPassword.required"))
              .test(
                "match",
                t("user:user.yup.confirmPassword.match"),
                function validate(value) {
                  return this.parent.password === value;
                },
              ),
          }),
      language: yup.string().oneOf(["en", "nl"]).required(),
      roles: yup
        .array()
        // .of(
        //   yup.object().shape({
        //     value: yup.string().oneOf(roleOptions),
        //   })
        // )
        .of(yup.string().oneOf(roleOptions))
        .required()
        .min(1),
    });
  }, [id, roleOptions, t]);

  const methods = useForm<UserFieldValues>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    shouldUnregister: true,
    mode: "onChange",
  });

  const { data: userData, loading } = useQuery<{ user: IUser }>(QUERY_USER, {
    skip: !id,
    variables: { id },
    onCompleted: ({ user }) => {
      methods.reset(user);
    },
  });

  const userRoles = useMemo(() => userData?.user?.roles || [], [userData]);

  const currentUserRoles = useMemo(
    () => currentUserData?.me?.roles || [],
    [currentUserData],
  );

  const canUpdateUser = useMemo(() => {
    return (
      !id ||
      Math.min(...currentUserRoles.map((role) => UserRolesPriority[role])) <=
        Math.min(...userRoles.map((role) => UserRolesPriority[role]))
    );
  }, [id, currentUserRoles, userRoles]);

  const emailVerified = useMemo(
    () => userData?.user?.emailVerified,
    [userData],
  );

  const [onUpdate] = useMutation(UPDATE_USER, {
    refetchQueries: [
      {
        query: QUERY_USERS,
      },
    ],
  });

  const [onCreate] = useMutation(CREATE_USER, {
    refetchQueries: [
      {
        query: QUERY_USERS,
      },
    ],
  });

  const [onDelete] = useMutation(DELETE_USER, {
    refetchQueries: [
      {
        query: QUERY_USERS,
      },
    ],
  });

  const [onResendVerificationEmail] = useMutation(
    MUTATION_RESEND_VERIFICATION_EMAIL,
    {
      onCompleted: () => {
        toast.success<string>(t("user:user.toast.resendVerificationEmail"));
      },
      onError() {
        toast.error<string>(t("user:user.toast.resendVerificationEmailError"));
      },
    },
  );

  const onRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();

      history.replace(`/users`);

      return onDelete({ variables: { id } })
        .then(() => {
          toast.success<string>(t("user:user.toast.deleted"));
        })
        .catch((error) => {
          toast.error<string>(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          );
        });
    },
    [onDelete, id, history, t],
  );

  const onBeforeRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();

      setShow(true);
    },
    [setShow],
  );

  const roleSelectOptions = useMemo(
    () =>
      map(roleOptions, (value) => ({
        value,
        label: t(
          `userRoles:options.${
            value === "CLIENT_ADMIN" &&
            (clientType === "distributor" || clientType === "reseller")
              ? clientType.toUpperCase()
              : value
          }`,
        ),
      })),
    [roleOptions, clientType, t],
  );

  const onSubmit = (variables: UserFieldValues) => {
    const {
      passwordConfirm: _passwordConfirm,
      firstName,
      lastName,
      email,
      ...restOfVariables
    } = variables;

    const input = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email: email.trim(),
      ...restOfVariables,
      id,
    };

    if (id) {
      return onUpdate({ variables: { input } })
        .then(() => {
          toast.success<string>(t("user:user.toast.updated"));
        })
        .catch((error) => {
          toast.error<string>(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          );
        });
    }

    return onCreate({ variables: { input } })
      .then(
        ({
          data: {
            addUser: { id },
          },
        }) => {
          toast.success<string>(t("user:user.toast.created"));
          history.replace(`/users/${id}`);
        },
      )
      .catch((error) => {
        toast.error<string>(
          error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
        );
      });
  };

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item">
            <Link to="/users">{t("user:user.nav.user", { count: 2 })}</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {t("user:user.nav.user", { count: 1 })}
          </li>
        </ol>
      </nav>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-group">
                <label htmlFor="firstName">
                  {t("user:user.form.firstName")}
                </label>
                <Input
                  name="firstName"
                  className="form-control"
                  disabled={!canUpdateUser}
                />
              </div>
              <div className="form-group">
                <label htmlFor="lastName">{t("user:user.form.lastName")}</label>
                <Input
                  name="lastName"
                  className="form-control"
                  disabled={!canUpdateUser}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">{t("user:user.form.email")}</label>
                <div className="d-flex">
                  <div className="w-100">
                    <Input
                      name="email"
                      className="form-control"
                      autoComplete="email"
                      disabled={!!id}
                    />
                  </div>
                  {!!id && canUpdateUser && (
                    <Link to={`/users/${id}/email`}>
                      <Button variant="primary" className="ml-3">
                        {t("common:change")}
                      </Button>
                    </Link>
                  )}
                </div>
              </div>
              <RoleBlock roles={["ADMIN", "CLIENT_ADMIN", "CLIENT"]}>
                {loading ? (
                  <div className="my-4">
                    <LoadingSpinner />
                  </div>
                ) : (
                  <div>
                    {emailVerified ? (
                      <p>{t("user:user.form.emailVerified")}</p>
                    ) : (
                      <div>
                        <RoleBlock roles={["ADMIN"]}>
                          <div className="form-group mb-2">
                            <Controller
                              name="emailVerified"
                              render={({ field: { value, onChange } }) => {
                                return (
                                  <div className="form-group form-check">
                                    <input
                                      type="checkbox"
                                      id="emailVerified"
                                      name="emailVerified"
                                      className="form-check-input"
                                      checked={value}
                                      disabled={!canUpdateUser}
                                      onChange={() => onChange(!value)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="emailVerified"
                                    >
                                      {t(
                                        "user:user.form.autoEmailVerification",
                                      )}
                                    </label>
                                  </div>
                                );
                              }}
                            />
                          </div>
                        </RoleBlock>

                        {id && canUpdateUser && (
                          <Button
                            onClick={() => {
                              onResendVerificationEmail({
                                variables: {
                                  input: {
                                    id,
                                  },
                                },
                              });
                            }}
                          >
                            {t("user:user.form.resendEmailVerification")}
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </RoleBlock>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-group">
                <label htmlFor="password">{t("user:user.form.password")}</label>
                <Input
                  name="password"
                  type="password"
                  className="form-control"
                  autoComplete="new-password"
                  disabled={!canUpdateUser}
                />
              </div>

              <div className="form-group">
                <label htmlFor="password">
                  {t("user:user.form.confirmPassword")}
                </label>
                <Input
                  name="passwordConfirm"
                  type="password"
                  className="form-control"
                  autoComplete="new-password"
                  disabled={!canUpdateUser}
                />
              </div>

              <Controller
                name="roles"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <div className="form-group">
                    <label htmlFor="roles">{t("user:user.form.role")}</label>
                    <Select
                      closeMenuOnSelect={false}
                      isMulti
                      options={roleSelectOptions}
                      onChange={(nextValue) => {
                        const nextRoles: UserRole[] = map(nextValue, "value");

                        if (
                          nextRoles.length > 0 &&
                          Math.min(
                            ...userRoles.map((role) => UserRolesPriority[role]),
                          ) <
                            Math.min(
                              ...nextRoles.map(
                                (role) => UserRolesPriority[role],
                              ),
                            )
                        ) {
                          toast.warn<string>(t("userRoles:change_info"));
                        }

                        onChange(nextRoles);
                      }}
                      value={map(value, (value) => ({
                        value,
                        label: t(
                          `userRoles:options.${
                            value === "CLIENT_ADMIN" &&
                            (clientType === "distributor" ||
                              clientType === "reseller")
                              ? clientType.toUpperCase()
                              : value
                          }`,
                        ),
                      }))}
                      isDisabled={!canUpdateUser}
                      className={clsx({
                        "is-invalid": !!error,
                      })}
                    />
                    {!!error && (
                      <div className="invalid-feedback">{error.message}</div>
                    )}
                  </div>
                )}
              />
              <div className="form-group">
                <label htmlFor="language">{t("user:user.form.language")}</label>
                <select
                  {...methods.register("language")}
                  id="language"
                  className="custom-select"
                  disabled={!canUpdateUser}
                >
                  <option value="en">EN</option>
                  <option value="nl">NL</option>
                </select>
              </div>
            </div>
          </div>
          {canUpdateUser ? (
            <div className="row mt-3">
              <div className="col-12">
                <input type="submit" className="btn btn-primary" />
                <RoleBlock roles={["ADMIN", "CLIENT_ADMIN"]}>
                  {id && (
                    <button
                      onClick={onBeforeRemove}
                      className="btn btn-danger ml-3"
                    >
                      {t("common:delete")}
                    </button>
                  )}
                </RoleBlock>
              </div>
            </div>
          ) : (
            <p className="text-danger">{t("user:user.form.unauthorized")}</p>
          )}
        </form>
      </FormProvider>
      <Modal show={show} onHide={setShow} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{t("user:user.modal.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("user:user.modal.body")}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={setShow}>
            {t("common:cancel")}
          </Button>
          <Button variant="danger" onClick={onRemove}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

export default UserCreate;
