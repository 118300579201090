/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useState, useRef } from "react";

import { Controller, FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import set from "lodash/set";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { UPDATE_DIGITAL_RECEPTION_THEME } from "../../../../config/graphql/mutation";
import { QUERY_DIGITAL_RECEPTION_THEME } from "../../../../config/graphql/query";

import { upload } from "../../../../lib/hooks/useSocket";

import ColorPicker from "../../../../components/ColorPicker";
import ImageCropPicker from "../../../../components/ImageCropPicker";
import { useIsFrontAssistDomain } from "../../../../lib/hooks/useDomain";

interface FieldValues extends Pick<IDigitalReceptionTheme, "colors"> {
  images?: {
    hero?: { file: string; filename: string };
    logo?: { file: string; filename: string };
  };
}

const ThemeRoute = () => {
  const methods = useForm<FieldValues>({ shouldUnregister: true });

  const { screenId } = useParams<{ screenId: string }>();

  const isFrontAssist = useIsFrontAssistDomain();

  const previewRef = useRef<HTMLIFrameElement>(null);

  const { t } = useTranslation(["screens", "common"]);

  const postMessage = useCallback((message: string, targetOrigin = "*") => {
    return previewRef?.current?.contentWindow?.postMessage(
      message,
      targetOrigin,
    );
  }, []);

  const onChangePreview = useCallback(
    (name: string, value: any) => {
      const data = {};

      set(data, ["digitalReception", "theme", ...name.split(".")], value);

      const message = JSON.stringify({
        action: "update-digitalReception",
        data,
      });

      return postMessage(message);
    },
    [postMessage],
  );

  const { data: screenData } = useQuery<{
    digitalReception: IDigitalReception;
  }>(QUERY_DIGITAL_RECEPTION_THEME, {
    skip: !screenId,
    variables: {
      id: screenId,
    },
    onCompleted: ({ digitalReception: { theme } }) => {
      methods.reset({
        colors: theme?.colors,
      });
    },
  });

  const [onUpdate] = useMutation(UPDATE_DIGITAL_RECEPTION_THEME, {
    refetchQueries: [
      {
        query: QUERY_DIGITAL_RECEPTION_THEME,
        variables: { id: screenId },
      },
    ],
  });

  const onUpload = useCallback(async (images: FieldValues["images"]) => {
    let input: { hero?: string; logo?: string } = {};

    if (images?.hero) {
      await upload([images.hero]).then((r) => {
        input = {
          ...input,
          hero: r[0].id,
        };
      });
    }

    if (images?.logo) {
      await upload([images.logo]).then((r) => {
        input = {
          ...input,
          logo: r[0].id,
        };
      });
    }

    return input;
  }, []);

  const onSubmit = useCallback(
    async ({ images, ...rest }: FieldValues) => {
      const uploadedImages = await onUpload(images);

      const theme = {
        ...rest,
        images: uploadedImages,
      };

      onUpdate({
        variables: {
          input: {
            id: screenId,
            theme,
          },
        },
      }).then(() => {
        toast.success<string>(t("screens:screen.screenRoute.toast.updated"));

        const previewEl = document.querySelector<HTMLIFrameElement>("#preview");

        if (previewEl) {
          previewEl.src += "";
        }
      });
    },
    [onUpdate, onUpload, t, screenId],
  );

  return (
    <FormProvider {...methods}>
      <form className="row" onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="col-lg-4">
          {/* <div className="form-group mb-4">
            <label htmlFor="welcomeMessage">
              {t("screens:screen.theme.welcomeMessage")}
            </label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  {language.toUpperCase()}
                </span>
              </div>
              <input
                {...methods.register("welcomeMessage", {
                  onChange: ({ target: { value } }) => {
                    onChangePreview(`welcomeMessage.${language}`, value);
                  },
                })}
                className="form-control"
              />
            </div>
          </div> */}

          <Controller
            name="colors.primary"
            render={({ field: { value, name, onChange } }) => (
              <div className="form-group">
                <ColorPicker
                  label={t("screens:screen.theme.color.primary")}
                  value={
                    // eslint-disable-next-line no-nested-ternary
                    typeof value === "string"
                      ? value
                      : isFrontAssist
                      ? "#33435d"
                      : "#2D9CCA"
                  }
                  onChange={(value) => {
                    onChange(value);

                    onChangePreview(name, value);
                  }}
                />
              </div>
            )}
          />
          <Controller
            name="colors.background"
            render={({ field: { value, name, onChange } }) => (
              <div className="form-group">
                <ColorPicker
                  label={t("screens:screen.theme.color.background")}
                  value={typeof value === "string" ? value : "#ffffff"}
                  onChange={(value) => {
                    onChange(value);

                    onChangePreview(name, value);
                  }}
                />
              </div>
            )}
          />
          <Controller
            name="colors.textPrimary"
            render={({ field: { value, name, onChange } }) => (
              <div className="form-group">
                <ColorPicker
                  label={t("screens:screen.theme.color.primaryText")}
                  value={typeof value === "string" ? value : "#212529"}
                  onChange={(value) => {
                    onChange(value);

                    onChangePreview(name, value);
                  }}
                />
              </div>
            )}
          />
          <Controller
            name="images.hero"
            render={({ field: { name, value, onChange } }) => (
              <div className="form-group">
                <label htmlFor={name}>
                  {t("screens:screen.theme.image.hero")}
                </label>
                <ImageCropPicker
                  name={name}
                  aspect={1080 / 545}
                  value={value}
                  onChange={(fileBase64) => {
                    onChange(fileBase64);

                    onChangePreview(`${name}.absolutePath`, fileBase64.file);
                  }}
                />
                <small className="form-text text-muted">
                  {t("screens:screen.theme.image.description")}
                </small>
              </div>
            )}
          />
          <Controller
            name="images.logo"
            render={({ field: { name, value, onChange } }) => (
              <div className="form-group">
                <label htmlFor={name}>
                  {t("screens:screen.theme.image.logo")}
                </label>
                <ImageCropPicker
                  name={name}
                  allowsEditing={false}
                  value={value}
                  onChange={(fileBase64) => {
                    onChange(fileBase64);

                    onChangePreview(`${name}.absolutePath`, fileBase64.file);
                  }}
                />
                <small className="form-text text-muted">
                  {t("screens:screen.theme.image.description")}
                </small>
              </div>
            )}
          />
        </div>
        <div className="col-lg-8 row">
          <div className="col-12 mb-2">
            <div
              className=" position-absolute p-0"
              style={{ zIndex: 100, width: 600 / (1920 / 1080), height: 600 }}
            />
            <iframe
              id="preview"
              ref={previewRef}
              title={`screen-${screenId}`}
              className="border border-dark"
              src={`${screenData?.digitalReception?.previewUrl}?theme=true&preview=true`}
              style={{
                width: 600 / (1920 / 1080),
                height: 600,
              }}
              frameBorder={0}
              marginHeight={0}
              marginWidth={0}
            />
          </div>
        </div>
        <div className="col-12">
          <input type="submit" className="btn btn-primary" />
        </div>
      </form>
    </FormProvider>
  );
};

export default ThemeRoute;
