import { useMemo } from "react";

export const useDomain = () => {
  return useMemo(() => {
    const { hostname } = window.location;
    const parts = hostname.split(".");

    return parts.length > 2 ? parts.slice(-2).join(".") : hostname;
  }, []);
};

export const useIsFrontAssistDomain = () => {
  const domain = useDomain();

  return useMemo(() => domain === "frontassist.eu", [domain]);
};
