import React, { useCallback, useEffect, useMemo, useRef } from "react";

import clsx from "clsx";

import Button from "react-bootstrap/Button";

import { useTranslation } from "react-i18next";

import { useQuery, useApolloClient } from "@apollo/client";

import { useClickAway, useToggle } from "react-use";

import { useAuth } from "../context/Auth";
import { useSidebarState } from "../context/Sidebar";

import { currentLocale } from "../context/Apollo/cache";

import { QUERY_CURRENT_USER, QUERY_LOCALE } from "../config/graphql/query";

const Navbar = () => {
  const { i18n, t } = useTranslation();

  const client = useApolloClient();

  const { data: currentUserData } = useQuery<{ me: IUser }>(QUERY_CURRENT_USER);

  const currentUserEmail = useMemo(
    () => currentUserData?.me?.email,
    [currentUserData],
  );

  const currentUserRoles = useMemo(
    () => currentUserData?.me?.roles,
    [currentUserData],
  );

  const clientType = useMemo(
    () => currentUserData?.me?.client.type,
    [currentUserData],
  );

  const updatedRoles = currentUserRoles?.map((role) =>
    role === "CLIENT_ADMIN" &&
    (clientType === "distributor" || clientType === "reseller")
      ? clientType.toUpperCase()
      : role,
  );

  const currentUserFullName = useMemo(
    () =>
      [currentUserData?.me?.firstName, currentUserData?.me?.lastName].join(" "),
    [currentUserData],
  );

  const currentUserClient = useMemo(
    () => currentUserData?.me?.client.title,
    [currentUserData],
  );

  const [currentUserVisible, toggleCurrentUser] = useToggle(false);

  const { logout } = useAuth();

  const onLogout = useCallback(
    (e: any) => {
      e.preventDefault();

      logout();
    },
    [logout],
  );

  const { data } = useQuery(QUERY_LOCALE);

  const locale = data?.locale ?? "en";

  /**
   * Change i18n locale whenever reactive variable gets updated
   */
  useEffect(() => {
    i18n.changeLanguage(locale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  const { visible, toggle: toggleSidebar } = useSidebarState();

  const onChangeLocale = useCallback(
    async ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      window.localStorage.setItem("Locale", value);

      currentLocale(value);

      await client.resetStore();
    },
    [client],
  );

  const currentUserPopup = useRef<HTMLUListElement>(null);

  useClickAway(currentUserPopup, (event) => {
    if (
      event.target &&
      (event.target as HTMLElement)?.className !== "user-icon"
    ) {
      toggleCurrentUser();
    }
  });

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom justify-content-between">
      <button
        className={clsx("btn btn-primary", {
          "fa fa-angle-double-right": !visible,
          "fa fa-angle-double-left": visible,
        })}
        id="menu-toggle"
        onClick={() => toggleSidebar()}
      />

      <div className="d-flex align-items-center">
        <form className="form-inline">
          <div className="btn-group btn-group-toggle" data-toggle="buttons">
            <label
              className={clsx("btn btn-secondary", { active: locale === "en" })}
            >
              <input
                id="en"
                value="en"
                type="radio"
                name="options"
                onChange={onChangeLocale}
                checked={locale === "en"}
              />
              EN
            </label>
            <label
              className={clsx("btn btn-secondary", { active: locale === "nl" })}
            >
              <input
                id="nl"
                value="nl"
                type="radio"
                name="options"
                checked={locale === "nl"}
                onChange={onChangeLocale}
              />
              NL
            </label>
          </div>
        </form>
        <div className="ml-3">
          <button className="user-icon" onClick={() => toggleCurrentUser()}>
            <div />
          </button>
          {!!currentUserVisible && (
            <ul ref={currentUserPopup} className="user-info">
              <li>
                {t("navbar:currentUser.fullName")}
                <span className="text-primary">{currentUserFullName}</span>
                <a
                  className="ml-3 fa fa-cog outline-none text-decoration-none"
                  style={{ fontSize: "1.3rem" }}
                  href="/profile/settings"
                >
                  &nbsp;
                </a>
              </li>
              <li>
                {t("navbar:currentUser.email")}
                <span className="text-primary">{currentUserEmail}</span>
              </li>
              <li>
                {t("navbar:currentUser.client")}
                <span className="text-primary">{currentUserClient}</span>
              </li>
              <li>
                {t("navbar:currentUser.role", {
                  count: updatedRoles?.length,
                })}
                <span className="text-primary">{updatedRoles?.join(", ")}</span>
              </li>
              <li className="text-center">
                <Button className="w-100" onClick={onLogout}>
                  {t("navbar:button.logout")}
                </Button>
              </li>
            </ul>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
