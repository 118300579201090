import React, { useMemo, useContext, useState } from "react";

import get from "lodash/get";

import { useQuery, useApolloClient } from "@apollo/client";

import {
  QUERY_AUTH,
  QUERY_CLIENT,
  QUERY_CURRENT_USER,
} from "../config/graphql/query";

type ClientContextValue = {
  client?: IClient;
};

const ClientContext = React.createContext<ClientContextValue>(undefined!);

const { Provider } = ClientContext;

export const useCurrentClient = () => {
  const { client } = useContext(ClientContext);

  return client;
};

export const ClientProvider = React.memo(
  ({ children }: { children: React.ReactNode }) => {
    const { cache } = useApolloClient();

    const [clientId, setClientId] = useState<string | null>(null);

    useQuery<{ clientId?: string; token: string }>(QUERY_AUTH, {
      onCompleted: (data) => {
        if (data?.clientId) {
          setClientId(data.clientId);
        }
      },
    });

    const { data: userData } = useQuery<{ me: IUser }>(QUERY_CURRENT_USER, {
      onCompleted: ({ me }) => {
        if (clientId) return;

        const newClientId = me?.client?.id;

        if (newClientId) {
          cache.writeQuery({
            query: QUERY_AUTH,
            data: { clientId: newClientId },
          });

          setClientId(newClientId);
        }
      },
    });

    const defaultClient = useMemo(() => userData?.me?.client, [userData]);

    const { data: clientData, loading: isLoadingClient } = useQuery<{
      client: IClient;
    }>(QUERY_CLIENT, {
      variables: { id: clientId },
      skip: !clientId,
    });

    const client = useMemo(() => {
      if (isLoadingClient) {
        return undefined;
      }

      const selectedClient = get(clientData, "client");

      return selectedClient || defaultClient;
    }, [clientData, defaultClient, isLoadingClient]);

    if (!client) {
      return null;
    }

    return <Provider value={{ client }}>{children}</Provider>;
  },
);
